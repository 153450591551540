.styledButton {
  background-color: #00334E;
  color: #fff;
  text-align: center;
  font-size:x-large;
  padding: 0.8rem;
  margin: 2px;

  &:hover {
    cursor: pointer;
  }
}