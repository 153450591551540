.customerHistoricPurchases {
  position: relative;

  .groupHeader {
    min-height: 20px;
    color: black;
    font-weight: bold;
  }

  .itemRow {
    margin: 4px;
  }
}