.featureButton {
  height: 5rem;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  text-align: center;
  padding: 1rem;

  &:hover {
    cursor: pointer;
  }

  img,
  svg {
    height: 2rem;
  }
}