.gridItem {
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
    background: darken(#eee, 10%) !important;
  }

  .itemGroup {
    >div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:not(:last-child) {
      padding-right: .25rem;
    }
  }

  &.odd {
    background: #eee;
  }
}