.floatingWindow {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.5);
  z-index: 2000;
}

.floatingWindow>.floatingContainer {
  position: absolute;
  background: #fff;
  color: #000;
  box-shadow: 0.25rem 0.25rem 1.25rem rgb(0, 0, 0);
  border: 1px solid rgba(0, 0, 0, .125);
  max-height: calc(96vh);
  max-width: 96%;
  min-width: 10rem;
}

.floatingWindow>.floatingContainer>.header {
  width: 100%;
  height: 2rem;
  line-height: 2rem;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, .125);
}

.floatingWindow>.floatingContainer>.header span {
  margin: 0;
  padding-left: .5rem;
  width: 100%;
  line-height: 2rem;
  font-size: 18px;
}

.floatingWindow>.floatingContainer>.header>.headerCloseButton {
  padding: 0rem 0.5rem;
  cursor: pointer;
  font-weight: bold;
  width: 2rem;
  height: 2rem;
  
  svg {
    margin-top: .25rem;
  }
}

.floatingWindow>.floatingContainer>.content {
  max-height: calc(96vh - 2rem);
  /* display: flex;
      flex-flow: column nowrap;
      padding: 0.5rem;
      flex: 1; */
  border: 1px solid transparent;
  padding: 1rem;
  overflow-y: auto;
}