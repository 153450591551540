.customerRebates {
  min-height: 15rem;
  position: relative;

  .items {
    max-height: calc(100vh - 14rem);
    overflow-y: auto;
  }

  .checkbox {
    font-weight: bold;
  }

  .header {
    padding-right: 1rem !important;
  }
}