.start {
  background: linear-gradient(-45deg, #2b505e, #8ed3c3);
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  >div {
    width: 100%;
  }

  .row {
    width: 100%;

    >div {
      padding: 1rem;
    }
  }
}