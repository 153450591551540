.defaultInput {
  margin: .25rem 0;
  width: 100%;

  input {
    width: 100%;
    padding: 8px 16px;
    appearance: none;
    font-size: 0.75rem;

    &.error {
      border-color: red;
    }
  }

  input[type="date"] {
    padding: 6px 16px;
  }

  input:disabled {
    background-color: #eee;
  }

  label {
    display: block;
    margin-bottom: 5px;

    .required {
      color: red;
      font-weight: bold;
    }
  }

  .errormsg {
    color: red;
  }
}