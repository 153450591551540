.imageButton {
  //width: 5rem;
  max-width: 6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: rgb(196, 196, 196);

  .text {
    font-weight: bold;
    min-height: 2.5rem;
    justify-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }

  img,
  svg {
    width: 70%;
    height: auto;
  }

  &.active {
    color: #0598A6;
  }

  &.border {
    border: 2px solid #fff;
    padding: 0 .125rem;

    &.active {
      border: 2px dotted red !important;
    }
  }

  /* General styles that can be applied to this button */
  &.blue {
    color: #00334e;
  }

  &.smal {
    max-width: 2rem;
  }

  /* *** */
}
.imageButton:hover{
  cursor: pointer;
}