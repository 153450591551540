@import "sass/_variables";

.customer {
  .header {
    background-color: #87a4b0;
    min-height: 2rem;
    color: white;
    padding: 0 .25rem;
    display: flex;
    align-items: center;
    border-top: 2px solid #000;
  }

  .overview {
    padding: .5rem;

    .details {
      padding-right: 2rem;
      border-right: 1px solid #000;

      .detailswrapper {
        max-height: calc(100vh - 9.5rem);
        overflow-y: auto;
      }
 
      .detailNavigation{
        position: absolute;
        bottom: 0rem;
        width: 33%;
      }

      .row:not(.header):not(.image) {
        padding: 0.5rem 0.5rem 0.5rem 0;
      }
    }

    .data {
      padding-left: 2rem;
      max-height: calc(100vh - 9.5rem);
      overflow-y: auto;
    }

    .navigation {
      position: absolute;
      bottom: .5rem;
      right: .5rem;
      width: 66%;
      padding-top: 1rem;
    }

    @media screen and (max-width: $screen-xs-max) {
      .details {
        padding-right: 0;
        border-right: none;

        .detailNavigation{
          position: relative;
          width: 100%;
        }

        .row {
          padding: 0.5rem;
        }
        .detailswrapper{
          max-height: none;
        }
      }

      .data {
        padding-left: 0;

        .navigation{
          position: relative;
          width: 100%;
          padding-left: .5rem;
        }
      }
    }
  }
}