.checkBoxSelect {
  width: 100%;
  position: relative;

  .selectBox {
    position: relative;
    padding-bottom: .21rem;
    padding-top: .25rem;
  }

  .selectBox select {
    width: 100%;
  }

  .overSelect {
    position: absolute;
    width: inherit;
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    white-space: nowrap;

    align-items: center;
    user-select: none;
    padding-left: .5rem;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    .selectedText {
      display: flex;
    }
  }

  .boxes {
    margin-top: -0.20rem;
    position: absolute;
    width: 100%;
    -webkit-box-shadow: 0px 3px 10px -1px rgba(0, 0, 0, 0.4);
    box-shadow: 0px 3px 10px -1px rgba(0, 0, 0, 0.4);
    z-index: 1;
    display: block;
    background-color: white;
  }

  .selectOpt {
    position: relative;
    cursor: default;
    user-select: none;

    .checkText {
      position: inherit;
      padding-left: .8rem;
      padding-top: .4rem;
      width: 70%;
    }
  }

  .selectOpt:hover {
    background-color: #3297FD;
  }

  .checkboxes {
    margin-left: .5rem;
    margin-top: .5rem;
    margin-bottom: .5rem;
    height: 1rem;
    width: 1rem;
  }
}