.loadingCloud {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .wrapper {
    width: 12rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  img,
  svg {
    width: 100%;
    height: auto;
  }

  .cloud {
    position: relative;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    width: 3rem;
    top: 0;

    &.first {
      left: -1rem;
      animation-name: leftToRight;
      color:#9c9c9c;
    }

    &.second {
      top: -1.5rem;
      left: 1rem;
      width: 2rem;
      animation-name: rightToLeft;
      color:#c9c9c9e0;
      animation-duration: 2s;
    }
  }

  @keyframes leftToRight {
    0% {
      transform: translateX(-10%);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes rightToLeft {
    0% {
      transform: translateX(15%);
    }

    100% {
      transform: translateX(0);
    }
  }
}