.rebateListItem {
  svg {
    width: .75rem;

    &.down {
      color: red;
    }

    &.up {
      color: green;
    }
  }
}