.login {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  flex-direction: column;

  form {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;

    div {
      padding-top: .25rem;
    }

    button {
      margin-top: 1rem;
    }
  }
}