@import "src/sass/variables";

.customerEdit {
  .floatingContainer {
    width: 90%;
    max-width: 700px;

    .button {
      margin-top: .5rem;
    }
  }

  .groupHeader {
    font-weight: bold;
    margin: .25rem 0 .5rem 0;
    padding: 0;
    // margin-block-start: 0;
    // margin-block-end: 0;
    // margin-bottom: 3rem;
  }

  .metricsHeader {
    margin-bottom: .8rem;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  @media screen and(min-width: $screen-xs-max) {
    .row {
      >div:nth-child(2) {
        padding-left: 1.5rem;
      }
    }
  }
}