.printingWindow {
  .printingWindowContainer {
    min-width: 18rem;

    .formButtons {
      margin-top: 1.5rem;

      button.button {
        width: 8rem;
      }
    }

    .navigation {
      padding-top: 1rem;
    }
  }
}