.defaultSelect {  
  margin: .25rem 0;

  select {
    width: 100%;
    height: 100%;
    display: block;
    line-height: inherit;
    padding-right: 2.5rem;

    &.error {
      border-color: red;
    }
  }

  label {
    display: block;
    margin-bottom: 5px;
  }

  .errormsg {
    color: red;
  }
}