.customersFilter {
  padding: .5rem;

  .filters {
    &>div {
      &:not(:last-child) {
        padding-right: 1rem;
      }
    }
  }

  button {
    margin: .25rem 0;
  }

  .showFilterButton {
    display: none;
  }

  @media screen and (max-width: 670px) {
    position: absolute;
    background: white;
    z-index: 100;
    border: 1px solid rgb(218, 218, 218);
    width: calc(100% - 3rem);
    top: 2.8rem;
    transform: translateX(-100%);
    transition: transform .5s;

    &.show {
      transform: translateX(0);
    }

    .showFilterButton {
      display: block;
      background-color: #ffff;
      opacity: 0.95;
      position: absolute;
      right: -1.5rem;
      top: -.05rem;
      width: 1.5rem;
      height: 3rem;
      font-weight: 900;
      color: rgb(124, 124, 124);
      text-align: center;
      overflow: visible;

      writing-mode: vertical-rl;
      border-top: 1px solid rgb(218, 218, 218);
      border-bottom: 1px solid rgb(218, 218, 218);
      border-right: 1px solid rgb(218, 218, 218);
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }

    .showFilterButton:hover {
      cursor: pointer;
    }
  }

  @media screen and (max-width: 670px) and (orientation: landscape) {        
    .filters {
      height: 14rem;
      overflow-y: scroll;        
    }      
  }
}
