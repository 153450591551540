.headerGroup {
  flex: 1;
  
  &:not(:last-child) {
    margin-right: .25rem;
  }

  .groupName {
    background: #26536D;
    color: #fff;
    text-align: center;
    font-weight: bold;
    border-bottom: 2px solid black;
    font-size: 14px;
    padding: .125rem;
  }

  .items {
    display: flex;
    border-bottom: 2px solid black;
    height: 2.5rem;
    align-items: center;

    &>div {
      flex: 1;
    }
  }
}